<template>
  <div class="business">
    <!-- 智慧化工园区 -->
    <div
      style="
        width: 100%;
        padding-bottom: 81px;
        padding-top: 70px;
        background-color: #f5f5f5;
      "
    >
      <div class="wisdom">
        <div class="wisdom-title">
          <img src="@a/images/business-images/zhhgyq.png" alt="" />
        </div>
        <div class="wisdom-container">
          <div class="wisdom-container-left">
            <div class="container-left-title">
              {{ partData.title }}
            </div>
            <div class="container-left-content" v-html="partData.content"></div>
            <div class="container-left-line">
              <div class="line"></div>
            </div>
          </div>
          <div class="wisdom-container-right">
            <el-carousel
              trigger="click"
              height="500px"
              arrow="never"
              v-if="intelligentData.length"
            >
              <!-- <el-carousel-item v-for="item in 4" :key="item">
              <h3 class="small">{{ item }}</h3>
            </el-carousel-item> -->
              <el-carousel-item v-for="item in intelligentData" :key="item.id">
                <div class="item-banner">
                  <img :src="item.imagePath" alt="" />
                  <div class="desc">
                    <div v-html="item.description"></div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="wisdom-container-img" v-else>
              <img :src="intelligentData.imagePath" alt="" />
              <div class="desc">
                <div
                  class="wisdom-container-txt"
                  v-html="intelligentData.description"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 解决方案架构 -->
    <div
      style="
        width: 100%;
        background-color: #fff;
        padding-top: 70px;
        padding-bottom: 66px;
      "
    >
      <div class="solution">
        <div class="solution-title">
          <img src="@a/images/business-images/jjfa.png" alt="" />
        </div>
        <div class="solution-tu">
          <img src="~@a/images/business-images/jjfa-tu.png" alt="" />
        </div>
        <div class="solution-content">
          <div class="solution-content-title">
            {{ programmeData.summary }}
          </div>
          <div class="solution-content-desc">
            {{ programmeData.description }}
          </div>
        </div>
      </div>
    </div>
    <!-- 其他业务 -->
    <div
      style="
        width: 100%;
        padding-top: 70px;
        padding-bottom: 80px;
        background-color: #f5f5f5;
      "
    >
      <div class="other">
        <div class="other-title">
          <img src="~@a/images/business-images/qita.png" alt="" />
        </div>
        <div class="other-nav">
          <!-- 
            智慧城市
            互联网+政务
            软件智能应用
            硬件集成
            IT信息系统运维服务 -->
          <ul class="other-nav-list">
            <template v-for="(item, index) in casesData">
              <li
                class="other-nav-li"
                :key="index"
                :class="{ active: active == index ? 'active' : '' }"
                v-if="item.typeCode !== 'wisdom_park'"
                @click="otherNavIndex(index, item.typeCode)"
              >
                <div class="other-nav-li-title">
                  {{ item.name }}
                </div>
                <img src="~@a/images/business-images/title_bottom.png" alt="" />
              </li>
            </template>
          </ul>
        </div>
        <div class="other-container">
          <div class="other-container-left">
            <div class="other-container-left-title">
              <img src="~@a/images/business-images/ju-huise.png" alt="" />
              <div class="container-left-title">
                {{ otherData.categoryName }}
              </div>
            </div>
            <div class="other-container-left-desc">
              {{ otherData.description }}
            </div>
            <div class="other-container-left-line">
              <div class="left-line">
                <img src="~@a/images/business-images/lujing.png" alt="" />
              </div>
            </div>
          </div>
          <div class="other-container-right">
            <img :src="otherData.imagePath2" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div style="height: 20px; background-color: #fff;"></div>
  </div>
</template>
<script>
import { GetSpecial } from '@/api/aboutApi.js'
import { GetCases } from '@/api/caseApi.js'

export default {
  data() {
    return {
      active: 1, // 默认选中状态
      //智慧园区简介
      partData: {},
      //智慧园区轮播
      intelligentData: [],
      programmeData: {}, //解决方案架构
      casesData: [], //其他业务nav
      otherData: [], //其他业务
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let part = await GetSpecial('wisdom_part')
      let intelligent = await GetSpecial('intelligent')
      let programme = await GetSpecial('programme')
      let smart = await GetSpecial('smart_city')
      let cases = await GetCases()
      this.partData = part.result
      this.intelligentData = intelligent.result
      this.programmeData = programme.result
      this.otherData = smart.result
      this.casesData = cases.result
    },
    otherNavIndex(index, type) {
      this.active = index
      // console.log(type)
      GetSpecial(type).then((res) => {
        if (res.code == 0) {
          this.otherData = res.result
          // console.log(this.otherData)
        }
      })
    },
  },
  components: {},
}
</script>
<style lang="less" scoped>
.business {
  // 智慧化工园区
  .wisdom {
    width: 1200px;
    margin: 0 auto;
    .wisdom-title {
      width: 800px;
      height: 93px;
      margin: 0 auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .wisdom-container {
      display: flex;
      width: 1200px;
      height: 500px;
      margin: 43px auto 0 auto;
      .wisdom-container-left {
        width: 400px;
        height: 100%;
        background-image: url('~@a/images/business-images/juxing.png');
        position: relative;
        .container-left-title {
          width: 320px;
          height: 40px;
          line-height: 40px;
          margin: 53px auto 0 auto;
          font-size: 28px;
          color: #fff;
        }
        .container-left-content {
          width: 320px;
          line-height: 36px;
          min-height: 290px;
          font-size: 18px;
          margin: 25px auto 0 auto;
          color: #fff;
        }
        .container-left-line {
          width: 320px;
          height: 3px;
          margin: 45px auto 0 auto;
          .line {
            width: 120px;
            height: 3px;
            background: #fff;
          }
        }
      }
      .wisdom-container-right {
        width: 800px;
        height: 500px;
        position: relative;
        .wisdom-container-img {
          width: 100%;
          height: 100%;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .desc {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 140px;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          .wisdom-container-txt {
            width: 720px;
            text-align: left;
            height: 62px;
            margin: 27px 40px 51px 40px;
            font-size: 18px;
            font-family: MicrosoftYaHei;
            line-height: 36px;
          }
        }

        /deep/ .el-carousel {
          .el-carousel__container {
            .el-carousel__item {
              .item-banner {
                position: relative;
                .desc {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  color: #fff;
                  z-index: 1000;
                  width: 100%;
                  height: 140px;
                  background: rgba(0, 0, 0, 0.5);
                  margin: 0 auto;
                  div {
                    width: 720px;
                    text-align: left;
                    height: 62px;
                    margin: 27px 40px 51px 40px;
                    font-size: 18px;
                    font-family: MicrosoftYaHei;
                    line-height: 36px;
                  }
                }
                > img {
                  width: 800px;
                  height: 500px;
                }
              }
            }
          }
          .el-carousel__indicators {
            .el-carousel__indicator {
              &.is-active {
                .el-carousel__button {
                  background-color: #ef8804;
                  height: 5px;
                  width: 13px;
                  height: 13px;
                  border-radius: 50%;
                }
              }
              .el-carousel__button {
                height: 13px;
                width: 13px;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
  // 解决方案架构
  .solution {
    background-color: #fff;
    width: 1200px;
    margin: 0 auto;
    .solution-title {
      width: 800px;
      height: 93px;
      margin: 0 auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .solution-tu {
      width: 1200px;
      height: 570px;
      margin: 45px auto 0 auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .solution-content {
      width: 1200px;
      margin: 0 auto;
      .solution-content-title {
        font-size: 28px;
        font-family: MicrosoftYaHei;
        line-height: 34px;
        color: #000000;
        margin-top: 34px;
      }
      .solution-content-desc {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        line-height: 24px;
        color: #666666;
        margin-top: 11px;
      }
    }
  }
  //其他业务
  .other {
    width: 1200px;
    margin: 0 auto;
    .other-title {
      width: 800px;
      height: 93px;
      margin: 0 auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .other-nav {
      width: 1200px;
      margin: 0 auto;
      .other-nav-list {
        margin-top: 95px;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.5px solid #E0E0E0;
        .other-nav-li {
          position: relative;
          height: 64px;
          line-height: 64px;
          cursor: pointer;
          .other-nav-li-title {
            width: 212px;
            height: 100%;
            text-align: center;
            font-size: 18px;
            color: #333;
          }
          &.active {
            .other-nav-li-title {
              font-weight: bold;
            }
            > img {
              display: block;
            }
          }
          > img {
            position: absolute;
            bottom: 0;
            width: 212px;
            height: 8px;
            display: none;
          }
        }
      }
    }
    .other-container {
      display: flex;
      width: 1200px;
      height: 500px;
      margin-top: 39px;
      .other-container-left {
        width: 400px;
        height: 100%;
        background-image: url('~@a/images/business-images/juxing-huise.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border: 1px solid #E0E0E0;
        .other-container-left-title {
          width: 320px;
          height: 24px;
          margin: 54px auto 30px auto;
          position: relative;
          .container-left-title {
            position: absolute;
            top: -20px;
            font-size: 28px;
            font-family: MicrosoftYaHei-Bold;
            line-height: 34px;
            color: #333333;
            font-weight: 700;
          }
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .other-container-left-desc {
          width: 320px;
          height: 326px;
          margin: 0 auto;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          line-height: 36px;
          color: #666666;
        }
        .other-container-left-line {
          width: 320px;
          margin: 10px auto 0 auto;
          .left-line {
            width: 120px;
            height: 10px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .other-container-right {
        width: 800px;
        height: 100%;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
